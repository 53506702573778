.logoSliderimages {
  padding: 15px;
  .brandLogoDesktop {
    display: block;
  }
  .brandLogoMobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .logoSlider {
    .logoSliderheading {
      h2 {
        font-size: 20px !important;
        font-weight: 600 !important;
        letter-spacing: 0.03rem;
      }
    }
  }
  .logoSliderimages {
    padding-top: 0px;
    .brandLogoDesktop {
      display: none;
    }
    .brandLogoMobile {
      display: block;
    }
  }
}

.custom_container {
  min-width: 100% !important;
  background-color: #ffffff;
}

@media (min-width: 992px) {
  .custom_container {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}

@media (min-width: 768px) {
  .custom_container {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .custom_container {
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
