.ugAboutCp {
  color: rgb(255, 255, 255);
  padding: 100px 0px;
  h6 {
    font-size: 1.3vw;
    letter-spacing: 0.75px;
    line-height: 1vw;
    margin-bottom: 24px;
    color: white;
    opacity: 0.9;
  }
  p {
    font-size: 1.2vw;
    letter-spacing: 0.75px;
    line-height: 1.8vw;
    color: #b6b6b6;
    /* opacity: 0.7; */
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .ugAboutCp {
    color: rgb(255, 255, 255);
    padding: 70px 15px;
    h6 {
      line-height: 22px;
      font-size: 4vw;
    }
    p {
      font-size: 3.5vw;
      letter-spacing: 0.75px;
      line-height: 5.5vw;
    }
  }
}

/* FAQ Ver - 3 */
.accoVer3 {
  width: 75%;
  max-width: 100%;
  margin: auto;
  background: #000000;
  padding-bottom: 30px;
  p {
    opacity: 0.8 !important;
    font-weight: 600 !important;
    vertical-align: baseline;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.05rem;
  }
}
.faqV3Heading {
  h3 {
    color: #ffffff;
    text-align: center;
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .accoVer3 {
    width: 100%;
  }
}
